import { NextPageCustom, UserError } from '@pepper/client';
import { withStaticProps } from '@pepper/client/ssr';

const ErrorPage: NextPageCustom<{
  error: Error;
}> = ({ error }) => {
  return <UserError error={error} />;
};

ErrorPage.config = (_props, t) => {
  return {
    title: t('common:errors.heading'),
    gtm: {
      category: 'error',
      pageTitle: 'Something went wrong',
    },
  };
};

export const getStaticProps = withStaticProps();

export default ErrorPage;
